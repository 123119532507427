import * as MATOMO from '@/constants/matomo';

export const matomoCreateEntrypointSuccess = (): void =>
  window._paq.push([
    MATOMO.EVENT_TYPE.TRACK_EVENT,
    MATOMO.ENTRYPOINT_MODAL_EVENT.ENTRYPOINT_SUBMIT_MODAL_BUTTON,
    MATOMO.EVENT_TYPE.SUBMISSION,
    MATOMO.ENTRYPOINT_MODAL_EVENT.CREATE_ENTRYPOINT_SUCCESSFUL
  ]);

export const matomoCreateEntrypointError = (): void =>
  window._paq.push([
    MATOMO.EVENT_TYPE.TRACK_EVENT,
    MATOMO.ENTRYPOINT_MODAL_EVENT.ENTRYPOINT_SUBMIT_MODAL_BUTTON,
    MATOMO.EVENT_TYPE.SUBMISSION,
    MATOMO.ENTRYPOINT_MODAL_EVENT.CREATE_ENTRYPOINT_ERROR
  ]);
