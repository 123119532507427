import { reactAppConfig } from '@/config/react-app-config';
import backend from '@/api/backend';
import { UserSettings } from './user-types';

export const getUserSettings = (): Promise<UserSettings> => {
  return backend
    .get(`${reactAppConfig.backendEnv}/client-settings`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(
        'Failed to fetch the user settings:',
        JSON.stringify(error)
      );
      return error.status;
    });
};
