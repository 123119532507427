import React, { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as AddIcon } from '@/assets/image/icons/add.svg';
import { ReactComponent as DeleteIcon } from '@/assets/image/icons/delete.svg';
import ArrowBackgroundImage from '@/assets/image/arrow_background.png';
import { Button, Input } from '@/components';
import styles from './style.module.css';

export type MetaData = {
  name: string;
  value: string;
};

type Props = {
  url: string;
  closeModal: () => void;
};

export const AddMetaDataModal: React.FC<Props> = ({ url, closeModal }) => {
  const metadataPrefix = 'meta_';
  const [metaData, setMetaData] = useState<MetaData[]>([
    {
      name: '',
      value: ''
    }
  ]);

  const handleChange =
    (field: keyof MetaData, index: number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const data = [...metaData];
      if (data[index]) {
        data[index][field] = event.target.value;
      }
      setMetaData(data);
    };

  const handleAddNewData = () => {
    setMetaData((prev) => [
      ...prev,
      {
        name: '',
        value: ''
      }
    ]);
  };

  const handleRemoveData = (index: number) => {
    const data = [...metaData];
    data.splice(index, 1);
    setMetaData(data);
  };

  const handleClose = () => {
    setMetaData([
      {
        name: '',
        value: ''
      }
    ]);
    closeModal();
  };

  const handleSubmit = () => {
    const copyUrl = new URL(url);

    metaData.forEach((data) => {
      if (data.name && data.value) {
        copyUrl.searchParams.append(
          `${metadataPrefix}${data.name}`,
          data.value
        );
      }
    });

    // When using `new URL()`, space is encoded as +. So, we need to replace + with %20.
    copyUrl.search = copyUrl.search.replace(/\+/g, '%20');

    navigator.clipboard.writeText(copyUrl.toString());

    toast.info('Copied!', {
      position: 'bottom-left'
    });

    handleClose();
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="rounded-sm shadow-lg bg-white max-w-[780px] h-[610px] w-full relative">
          <div className="absolute t-0 r-0 w-full flex justify-end pt-5 px-6 mb-2.5">
            <span
              className="leading-4 text-black text-3xl cursor-pointer"
              onClick={handleClose}
            >
              ×
            </span>
          </div>

          <div className="flex px-[80px] pt-[83px] pb-[76px] h-full">
            <div className="flex-1 flex flex-col">
              <h2 className="text-xl mb-3.5 opacity-80 font-telegraf mb-[26px]">
                Add MetaData
              </h2>

              <div
                className="flex items-center cursor-pointer mb-4 w-fit"
                onClick={handleAddNewData}
              >
                <AddIcon />
                <span className="text-xs font-bold ml-2.5 underline opacity-80">
                  Add value
                </span>
              </div>

              <div className="flex-1 overflow-auto">
                {metaData.map((item, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <div className="flex items-center gap-2">
                      <Input
                        className={styles.input}
                        onChange={handleChange('name', index)}
                        value={metaData[index].name}
                        name="name"
                        placeholder="Name"
                      />

                      <Input
                        className={styles.input}
                        value={metaData[index].value}
                        onChange={handleChange('value', index)}
                        name="value"
                        placeholder="Value"
                      />
                    </div>
                    {index > 0 && (
                      <span
                        className="ml-3 cursor-pointer"
                        onClick={() => handleRemoveData(index)}
                      >
                        <DeleteIcon className="w-3.5 h-3.5 text-gray-500" />
                      </span>
                    )}
                  </div>
                ))}
              </div>

              <div className="mt-8">
                <Button
                  className={styles.copyButton}
                  onClick={handleSubmit}
                  theme="primary"
                >
                  Copy
                </Button>
                <Button className={styles.cancelButton} onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-center pr-4 pl-8">
              <img
                src={ArrowBackgroundImage}
                width={105}
                height={248}
                alt="arrow-bg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
};
