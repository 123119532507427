import { useMemo } from 'react';
import { ChallengeComponentProps } from './Challenge.types';
import { ChallengeNewPassword } from './ChallengeNewPassword';
import { ChallengeSmsMFA } from './ChallengeSmsMFA';

type IMasterChallenge = ChallengeComponentProps;

export const Challenge = (props: IMasterChallenge) => {
  const ChallengeComponent = useMemo(() => {
    switch (props.user.challengeName) {
      case 'NEW_PASSWORD_REQUIRED':
        return <ChallengeNewPassword {...props} />;
      case 'SMS_MFA':
        return <ChallengeSmsMFA {...props} />;
      default:
        return null;
    }
  }, [props.user]);

  return ChallengeComponent;
};
