import axios from 'axios';
import { getEnvironmentVariable } from '@/config/utils';

export interface AppConfig {
  backendUrl: string;
  backendEnv: string;
  frontendUrl: string;
}

export const reactAppConfig: AppConfig = {
  backendUrl: getEnvironmentVariable('REACT_APP_BACKEND_URL'),
  backendEnv: getEnvironmentVariable('REACT_APP_BACKEND_ENVIRONMENT'),
  frontendUrl: getEnvironmentVariable('REACT_APP_FRONTEND_URL')
};

axios.defaults.baseURL = reactAppConfig.backendUrl;
