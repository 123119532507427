export const maxEntrypointDate = new Date(new Date().getTime() + 2147483647000);

export function formatDateUK(date: Date): string {
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
}

export function sortDate(
  a: Date | undefined,
  b: Date | undefined,
  asc: boolean
): number {
  if (a && b) {
    if (a < b) return asc ? -1 : 1;
    if (a > b) return asc ? 1 : -1;
  }
  return 0;
}

export function getOneYearAhead() {
  const d = new Date();
  d.setFullYear(d.getFullYear() + 1);
  return d;
}

function getTimeZoneOffsetInMs() {
  return new Date().getTimezoneOffset() * -60 * 1000;
}

export function timestampToDatetimeInputString(timestamp: number) {
  const date = new Date(timestamp + getTimeZoneOffsetInMs());
  const indexOfTimezone = 19;
  return date.toISOString().slice(0, indexOfTimezone);
}
