import React, { useState } from 'react';
import { UserSettings } from './user-types';
import { getUserSettings } from './user-api';

interface UserContextInterface {
  settings: UserSettings;
  fetchUserSettings: () => Promise<void>;
}

interface Props {
  children: React.ReactNode;
}

export const UserContext = React.createContext({} as UserContextInterface);

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [settings, setSettings] = useState({});

  const fetchUserSettings = async () => {
    const userSettings = await getUserSettings();
    setSettings(userSettings);
  };

  return (
    <UserContext.Provider
      value={{
        settings,
        fetchUserSettings
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
