import { Fragment } from 'react';
import { combineClassNames } from '@/utils/string';
import { Menu, Transition } from '@headlessui/react';
import { Hamburger } from '@/components';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store';
import { signOut } from '@/store/user';
import * as MATOMO from '@/constants/matomo';

export const MenuDropdown = () => {
  const dp = useAppDispatch();
  const navigate = useNavigate();

  const doSignOut = async () => {
    window._paq.push([
      MATOMO.EVENT_TYPE.TRACK_EVENT,
      MATOMO.SIGN_OUT_EVENT.SIGN_OUT,
      MATOMO.EVENT_TYPE.CLICK,
      MATOMO.SIGN_OUT_EVENT.SIGN_OUT_SUCCESS
    ]);

    await dp(signOut());
    navigate('/sign-in');
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          Options
          <Hamburger
            className="-mr-1 ml-2 h-5 w-5 text-apari-purple"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={doSignOut}
                  className={combineClassNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Sign out
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
