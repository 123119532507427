import { combineClassNames } from '@/utils/string';
import { InputHTMLAttributes, useState } from 'react';
import styles from './Input.module.css';
import { ReactComponent as EyeOpenIcon } from '@/assets/image/icons/eye-open.svg';
import { ReactComponent as EyeCrossedIcon } from '@/assets/image/icons/eye-crossed.svg';

export const InputPassword = (props: InputHTMLAttributes<HTMLInputElement>) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  return (
    <div className="relative">
      <input
        {...props}
        type={visiblePassword ? 'text' : 'password'}
        className={combineClassNames(styles.input, props.className)}
      />
      <div
        className="lg:mt-0 absolute top-2 right-4 flex justify-center items-center"
        onClick={() => setVisiblePassword(!visiblePassword)}
      >
        {!visiblePassword ? (
          <EyeCrossedIcon className="w-5 h-5 fill-gray-300" />
        ) : (
          <EyeOpenIcon className="w-5 h-5 fill-gray-300" />
        )}
      </div>
    </div>
  );
};
