import { ChallengeComponentProps } from './Challenge.types';
import styles from '../style.module.css';
import { Input, Button } from '@/components';
import { Formik } from 'formik';
import { Auth } from 'aws-amplify';

export const ChallengeNewPassword = ({
  user,
  onChallengeComplete,
  onChallengeFail
}: ChallengeComponentProps) => {
  const initialValues = {
    newPassword: ''
  };

  type FormValues = typeof initialValues;

  const submitChallenge = async ({ newPassword }: FormValues) => {
    try {
      const result = await Auth.completeNewPassword(user, newPassword);

      onChallengeComplete(result);
    } catch (e) {
      console.error(e);
      onChallengeFail?.(e);
    }
  };

  return (
    <div>
      <div className="w-full max-w-[392px] lg:max-w-[319px]">
        <p className="mb-2 mt-1 font-robot">Please set your new password</p>
      </div>

      <Formik
        onSubmit={submitChallenge}
        initialValues={initialValues}
        className="pr-0 lg:pr-8"
      >
        {({ values, isSubmitting, handleChange, submitForm }) => (
          <>
            <Input
              type={'password'}
              value={values.newPassword}
              onChange={handleChange}
              placeholder="Your new password"
              name="newPassword"
              className={styles.input}
              autoComplete="off"
            />

            <Button
              onClick={submitForm}
              className={styles.button}
              disabled={isSubmitting}
            >
              Set new password and login
            </Button>
          </>
        )}
      </Formik>
    </div>
  );
};
