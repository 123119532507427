import React, { PropsWithChildren, useState } from 'react';
import styles from './style.module.css';

type Props = PropsWithChildren & {
  message: React.ReactNode;
};

export const Tooltip: React.FC<Props> = ({ message, children }) => {
  const [isVisibleTooltip, setIsVisibleTooltip] = useState<boolean>(false);

  const showTooltip = () => {
    setIsVisibleTooltip(true);
  };

  const hideTooltip = () => {
    setIsVisibleTooltip(false);
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {isVisibleTooltip && <div className={styles.tooltip}>{message}</div>}
    </div>
  );
};
