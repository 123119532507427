import { Button } from '@/components/Form/Button';

export interface IModal {
  showModal: boolean;
  title: string;
  description: string;
  action: () => void | Promise<void>;
}

export const Modal = ({
  modal,
  setModal
}: {
  modal: IModal;
  setModal: React.Dispatch<React.SetStateAction<IModal>>;
}) => {
  return (
    <>
      {modal?.showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">{modal.title}</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() =>
                      setModal({
                        ...modal,
                        showModal: false
                      })
                    }
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    {modal.description}
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <Button
                    className="text-apari-purple bg-white font-bold border-2 border-apari-purple mr-2 h-10 flex items-center"
                    type="button"
                    onClick={() =>
                      setModal({
                        ...modal,
                        showModal: false
                      })
                    }
                  >
                    Close
                  </Button>
                  <Button
                    className="h-10"
                    theme="primary"
                    type="button"
                    onClick={modal?.action ? modal.action : undefined}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
