import { ChallengeComponentProps } from './Challenge.types';
import styles from '../style.module.css';
import { Input, Button } from '@/components';
import { Formik } from 'formik';
import { Auth } from 'aws-amplify';

export const ChallengeSmsMFA = ({
  user,
  onChallengeComplete,
  onChallengeFail
}: ChallengeComponentProps) => {
  const initialValues = {
    code: ''
  };

  type FormValues = typeof initialValues;

  const submitChallenge = async ({ code }: FormValues) => {
    try {
      const result = await Auth.confirmSignIn(user, code);

      onChallengeComplete(result);
    } catch (e) {
      console.error(e);
      onChallengeFail?.(e);
    }
  };

  return (
    <div>
      <div className="w-full max-w-[392px] lg:max-w-[319px]">
        <p>
          A message has been sent to your phone (
          {(user as any).challengeParam.CODE_DELIVERY_DESTINATION})
        </p>
        <p className="mt-6 mb-2 font-robot">Please enter the code</p>
      </div>

      <Formik
        onSubmit={submitChallenge}
        initialValues={initialValues}
        className="pr-0 lg:pr-8"
      >
        {({ values, isSubmitting, handleChange, submitForm }) => (
          <>
            <Input
              value={values.code}
              onChange={handleChange}
              placeholder="Your code"
              name="code"
              className={styles.input}
              autoComplete="off"
            />

            <Button
              onClick={submitForm}
              className={styles.button}
              disabled={isSubmitting}
            >
              Confirm sign in
            </Button>
          </>
        )}
      </Formik>
    </div>
  );
};
