export const EVENT_TYPE = {
  CLICK: 'Click',
  SUBMISSION: 'Submission',
  TRACK_EVENT: 'trackEvent'
};

export const SIGN_IN_EVENT = {
  SIGN_IN: 'Sign In',
  SIGN_IN_SUCCESS: 'Sign In Success',
  SIGN_IN_ERROR: 'Sign In Error'
};

export const SIGN_OUT_EVENT = {
  SIGN_OUT: 'Sign Out',
  SIGN_OUT_SUCCESS: 'Sign Out Success'
};

export const RESET_PASSWORD_EVENT = {
  RESET_PASSWORD: 'Reset Password'
};

export const ENTRYPOINT_MODAL_EVENT = {
  ENTRYPOINT_CREATE_MODAL_BUTTON: 'Entrypoint Create Modal Button',
  ENTRYPOINT_CANCEL_MODAL_BUTTON: 'Entrypoint Cancel Modal Button',
  ENTRYPOINT_SUBMIT_MODAL_BUTTON: 'Entrypoint Submit Modal Button',
  ENTRYPOINT_CREATE_MODAL: 'Entrypoint Create Modal',
  OPEN_ENTRYPOINT_CREATE_MODAL: 'Open Entrypoint Create Modal',
  CANCEL_ENTRYPOINT_CREATE_MODAL: 'Cancel Entrypoint Create Modal',
  CREATE_ENTRYPOINT_SUCCESSFUL: 'Create Entrypoint Successful',
  CREATE_ENTRYPOINT_ERROR: 'Create Entrypoint Error'
};

export const ENTRYPOINT_LIST_EVENT = {
  ENTRYPOINT_LIST_ACTION: 'Entrypoint List Action',
  ENTRYPOINT_LIST: 'Entrypoint List',
  COPY: 'Copy',
  SEND_LINK_OVER_EMAIL: 'Send link over email',
  DELETE: 'Delete',
  GO_TO_SESSION_PAGE: 'Go to session page'
};

export const SESSION_EVENT = {
  SESSION_LIST_ACTION: 'Session List Action',
  SESSION_LIST: 'Session List',
  REFRESH: 'Refresh',
  DOWNLOAD: 'Download',
  DELETE: 'Delete'
};
