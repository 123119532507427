import React, { useCallback, useEffect, useState } from 'react';
import { getSessions, deleteSession } from '~/Sessions/sessions-api';
import { SessionData, FetchSessionsResponse } from '~/Sessions/sessions-types';
import {
  SessionCardContent,
  SessionCardGrid
} from '@/components/CardGrid/SessionCardGrid';
import { useLocation, useParams } from 'react-router-dom';
import { userProfileSelector } from '@/store/user';
import { useAppSelector } from '@/store';
import styles from './style.module.css';
import queryString from 'query-string';

export const Sessions: React.FC = () => {
  const [sessions, setSessions] = useState<FetchSessionsResponse | undefined>(
    undefined
  );
  const [reloadState, setReloadState] = useState<string>('');
  const [sessionsAdaptedForCardGrid, setSessionsAdaptedForCardGrid] = useState<
    SessionCardContent[] | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);

  const { entrypointId } = useParams();
  const userProfile = useAppSelector(userProfileSelector);

  const location = useLocation();
  const [sessionId, setSessionId] = useState<string | null>();

  useEffect(() => {
    const queryParameters = queryString.parse(location.search);
    const sessionIdParameter = queryParameters?.sessionId;
    if (sessionIdParameter && typeof sessionIdParameter === 'string') {
      setSessionId(sessionIdParameter);
    }
  }, []);

  const fetchSessions = useCallback(async () => {
    if (entrypointId) {
      setLoading(true);
      const sessionData = await getSessions(entrypointId);

      // TODO: implement react-query library or simply write a custom hook to reduce boilerplate code when calling API (loading, error, data,...)
      setLoading(false);
      setSessions(sessionData);
    }
  }, [entrypointId]);

  useEffect(() => {
    fetchSessions();
  }, [reloadState]);

  useEffect(() => {
    if (sessions && sessions.Items.length > 0) {
      setSessionsAdaptedForCardGrid(
        mapFetchedSessionsDataFetchedToCardGridCompatibleData(sessions.Items)
      );
    }
  }, [sessions]);

  const mapFetchedSessionsDataFetchedToCardGridCompatibleData = (
    sessions: SessionData[]
  ): SessionCardContent[] =>
    sessions.map((session) => {
      return {
        id: session.SessionId,
        title: session.ReportedName ?? 'N/A',
        subtitle: session.ClosedAt,
        metadata: session.Metadata,
        inProgress: !session.ClosedAt,
        closedAt: session.ClosedAt
          ? new Date(session.ClosedAt.replace('Z', ''))
          : undefined,
        createdAt: session.CreatedAt
          ? new Date(session.CreatedAt.replace('Z', ''))
          : undefined,
        shouldHighlight: session.SessionId === sessionId
      };
    });

  const onDeleteSession = async (sessionId: string) => {
    try {
      await deleteSession(entrypointId || '', sessionId);
      setReloadState(sessionId);
    } catch (e) {
      console.log('Delete session errors');
      throw e;
    }
  };

  return (
    <div className={styles.sessions}>
      <h1 className={styles.title}>Sessions</h1>

      {loading ? (
        <div>Loading...</div>
      ) : (
        sessionsAdaptedForCardGrid &&
        userProfile &&
        entrypointId && (
          <SessionCardGrid
            gridContent={sessionsAdaptedForCardGrid}
            parentId={entrypointId}
            onDeleteSession={onDeleteSession}
            onRefresh={fetchSessions}
          />
        )
      )}
    </div>
  );
};
