import { combineClassNames } from '@/utils/string';
import { Menu } from '@headlessui/react';
import { Hamburger } from '@/components';

export type SelectMenuItem = {
  label: string;
  action?: () => void;
};

type SelectMenuProps = {
  classNameMenuBtn?: string;
  label: string;
  selectDropdownIcon?: JSX.Element;
  items: SelectMenuItem[];
};

export const SelectMenu: React.FC<SelectMenuProps> = ({
  classNameMenuBtn,
  label,
  selectDropdownIcon,
  items
}: SelectMenuProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className={classNameMenuBtn}>
        {label}
        {selectDropdownIcon ? (
          selectDropdownIcon
        ) : (
          <Hamburger
            className="-mr-1 ml-2 h-5 w-5 text-apari-purple"
            aria-hidden="true"
          />
        )}
      </Menu.Button>

      <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {items.map((item: SelectMenuItem, index: number) => (
          <div key={index} className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={item?.action}
                  className={combineClassNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {item.label}
                </div>
              )}
            </Menu.Item>
          </div>
        ))}
      </Menu.Items>
    </Menu>
  );
};
