import { PlusIcon } from '@heroicons/react/20/solid';
import { ReactComponent as DeleteIcon } from '@/assets/image/icons/delete.svg';
import { ReactComponent as DownloadIcon } from '@/assets/image/icons/download.svg';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import styles from './style.module.css';

export interface CardGridProps<CardContentType> {
  gridContent: CardContentType[];
  // eslint-disable-next-line no-unused-vars
  onCardClick?: (cardContent: CardContentType) => void;
  onDelete?: (cardContent: CardContentType) => void;
  onDownload?: (cardContent: CardContentType) => void;
}

export interface CardContent {
  id?: string;
  title?: string;
  subtitle?: string;
  url?: string;
  inProgress?: boolean;
  addNew?: boolean;
  shouldHighlight?: boolean;
}

export const CardGrid = <T extends CardContent = CardContent>(
  cardGridProps: CardGridProps<T>
) => {
  const navigate: NavigateFunction = useNavigate();
  const { gridContent, onCardClick, onDelete, onDownload } = cardGridProps;

  const handleDownLoad = (cardContent: T) => {
    onDownload?.(cardContent);
  };

  const handleDelete = (cardContent: T) => {
    onDelete?.(cardContent);
  };

  const handleClickCard = (cardContent: T) => {
    onCardClick?.(cardContent);
  };

  return (
    <>
      {gridContent.map((cardContent, key) =>
        !cardContent.inProgress && !cardContent.addNew ? (
          <div
            key={key}
            className={`${styles.sessionCard} + ${
              cardContent.shouldHighlight && 'bg-[#E0D8FE]'
            }`}
            onClick={() => handleClickCard(cardContent)}
          >
            <div>
              <h5 className={styles.cardTitle}>{cardContent.title}</h5>
              {cardContent.subtitle && (
                <>
                  <p className={styles.cardInfo}>
                    {cardContent.subtitle.split('T')[0]}
                  </p>
                  <p className={styles.cardInfo}>
                    {cardContent.subtitle.split('T')[1]}
                  </p>
                </>
              )}
            </div>
            <div className="flex items-center">
              <DownloadIcon
                className="cursor-pointer"
                onClick={() => handleDownLoad(cardContent)}
              />
              <DeleteIcon
                className="w-4 h-5 ml-5 cursor-pointer"
                onClick={() => handleDelete(cardContent)}
              />
            </div>
          </div>
        ) : (
          <div className={styles.sessionCard} key={key}>
            <div className="w-full h-full flex items-center justify-center">
              {cardContent.inProgress ? (
                <h3 className="truncate text-sm font-medium text-gray-900">
                  {'Running...'}
                </h3>
              ) : (
                <PlusIcon
                  className="h-20 w-20 text-gray-600 cursor-pointer"
                  aria-hidden="true"
                  onClick={() =>
                    navigate(cardContent.url || '/', { replace: true })
                  }
                />
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};
