import backend from '@/api/backend';
import { reactAppConfig } from '@/config/react-app-config';

import {
  EntrypointCreateData,
  FetchEntrypointCreateResponse
} from './entrypoint-create-types';

export const createEntrypoint = (
  data: EntrypointCreateData
): Promise<FetchEntrypointCreateResponse> => {
  return backend
    .post(`${reactAppConfig.backendEnv}/entrypoints`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Failed to create the entrypoint:', JSON.stringify(error));
      throw error;
    });
};
