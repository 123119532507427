import { logoBase64 } from '@/assets/image/logo';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import htmlToPdfmake from 'html-to-pdfmake';
import { isPlainObject, prettyPrint } from './object';
import {
  EmploymentReportType,
  IncomeBreakdownReport,
  IncomeSourcesBreakdown,
  IncomeSourceType,
  MONEY_FIELDS,
  ReportType,
  Source
} from '@/utils/pdf-types';

function camelCaseToFirstLetterUpperCase(str: string) {
  return capitalizeWords(str.replace(/([a-z])([A-Z])/, '$1 $2'));
}

function isIncomeBreakdownReport(report: any) {
  return report.tax_year && report.income;
}

function isPersonalReport(report: any) {
  return !report.tax_year && report.name;
}

function isEmploymentReport(report: any) {
  return report.employments != null;
}

function deductReportType(report: any) {
  return isPersonalReport(report)
    ? ReportType.PERSONAL
    : isIncomeBreakdownReport(report)
    ? ReportType.INCOME_BREAKDOWN
    : isEmploymentReport(report)
    ? ReportType.EMPLOYMENT
    : ReportType.UNKNOWN;
}

function deductEmploymentType(employmentReport: any) {
  return employmentReport.employments?.length > 0
    ? EmploymentReportType.NON_EMPTY_EMPLOYMENT
    : EmploymentReportType.EMPTY_EMPLOYMENT;
}

function capitalizeWords(string: string) {
  const stringSplittedBySpace = string.split(' ');
  return stringSplittedBySpace
    .map((word, index) => {
      return (
        word.charAt(0).toUpperCase() +
        word.slice(1).toLowerCase() +
        (index !== stringSplittedBySpace.length - 1 ? ' ' : '')
      );
    })
    .join('');
}

const divider = (margin) => ({
  margin,
  canvas: [
    {
      type: 'rect',
      x: 0,
      y: 0,
      w: 2073,
      h: 4,
      color: '#182394'
    }
  ]
});

function generatePaymentTables(payments: any[]) {
  const tableBody = buildPaymentTableBody(payments);

  return {
    layout: 'headerLineOnly',
    style: 'tableExample',
    table: {
      heights: 'auto',
      headerRows: 1,
      widths: ['auto', '*', '*', '*'],
      body: tableBody
    }
  };
}

export const generateDocumentDefinitionFromJSON = (
  reports: any,
  extraDocumentDefinitionProps?: Partial<TDocumentDefinitions>,
  metadata?: Record<string, string>
) => {
  function generateHeaderDependingOnReportType(
    reportType: ReportType,
    report: any
  ) {
    switch (reportType) {
      case ReportType.PERSONAL:
        return {
          text: `PERSONAL DATA REPORT`,
          style: 'header'
        };
      case ReportType.EMPLOYMENT:
        return {
          text: `EMPLOYMENT REPORT`,
          style: 'header'
        };
      case ReportType.INCOME_BREAKDOWN:
        return {
          text: `INCOME BREAKDOWN`,
          style: `header`
        };
      case ReportType.UNKNOWN:
        return {
          text: `UNKNOWN REPORT TYPE ${
            report.tax_year ? `FOR YEAR ${report.tax_year}` : ''
          }`,
          style: 'header'
        };
    }
  }

  function generateSubheaderDependingOnReportType(
    reportType: ReportType,
    report
  ) {
    return reportType === ReportType.EMPLOYMENT ||
      reportType === ReportType.INCOME_BREAKDOWN
      ? {
          text: `Tax Year: ${report.tax_year}`,
          bold: true,
          fontSize: 40,
          margin: [0, 4, 0, 120]
        }
      : {
          text: `\n\n`,
          style: 'header'
        };
  }

  function generateBodyDependingOnReportType(reportType: ReportType, report) {
    return reportType === ReportType.PERSONAL
      ? personalReportTable([report])
      : reportType === ReportType.EMPLOYMENT
      ? deductEmploymentType(report) ===
        EmploymentReportType.NON_EMPTY_EMPLOYMENT
        ? employmentSection(report.employments)
        : {
            text: `No employments were found by our system`,
            fontSize: 26
          }
      : reportType === ReportType.INCOME_BREAKDOWN
      ? generateIncomeBreakdownReportBody(report)
      : htmlToPdfmake(prettyPrint(report));
  }

  const documentDefinition: TDocumentDefinitions = {
    pageSize: {
      width: 2339,
      height: 3308
    },
    pageMargins: [140, 256, 125, 256],
    header: {
      columns: [
        {
          text: '',
          width: '*'
        },
        {
          image: logoBase64,
          width: 307,
          height: 97
        }
      ],
      margin: [0, 140, 123, 100]
    },
    content: [
      reports
        .sort(function (reportOne, reportTwo) {
          if (!reportOne.tax_year) {
            return -1;
          } else if (!reportTwo.tax_year) {
            return 1;
          }
          if (
            Number.parseInt(reportOne.tax_year.substring(0, 4)) -
              Number.parseInt(reportTwo.tax_year.substring(0, 4)) >
            0
          )
            return -1;
          return 1;
        })
        .map((_report: any, index: number) => {
          const report = JSON.parse(JSON.stringify(_report));
          currencyFormatObject(report);
          const reportType = deductReportType(report);

          return [
            generateHeaderDependingOnReportType(reportType, report),
            divider([0, 4, 0, 4]),
            generateSubheaderDependingOnReportType(reportType, report),
            generateBodyDependingOnReportType(reportType, report),
            index !== reports.length - 1
              ? { text: ``, pageBreak: 'after' }
              : undefined
          ];
        })
    ],
    styles: {
      header: {
        fontSize: 80,
        bold: true,
        font: 'Apotek',
        color: '#333'
      },
      subheader1: {
        fontSize: 60,
        bold: true,
        font: 'Apotek',
        color: '#333'
      },
      subheader2: {
        fontSize: 36,
        margin: [0, 0, 0, 10],
        font: 'Apotek',
        color: '#333'
      },
      tableData: {
        fontSize: 26,
        color: '#333'
      },
      counterText: {
        fontSize: 26,
        bold: true,
        margin: [0, 0, 5, 5]
      },
      whatsNextText: {
        fontSize: 26
      },
      tableCell: {
        fontSize: 26,
        margin: [0, 14, 0, 14],
        color: '#333'
      },
      alignRight: {
        alignment: 'right'
      }
    },
    ...extraDocumentDefinitionProps
  };

  if (metadata && !!Object.values(metadata)?.length) {
    documentDefinition.content[0]?.unshift([
      { text: `METADATA`, style: 'header' },
      divider([0, 4, 0, 4]),
      {
        text: `\n\n`,
        style: 'header'
      },
      metaDataTable([metadata]),
      { text: ``, pageBreak: 'after' }
    ]);
  }

  return documentDefinition;
};

const personalReportTable = (data: any) => {
  return {
    layout: 'headerLineOnly',
    style: 'tableExample',
    table: {
      body: buildPersonalDataBody(data),
      headerRows: 1,
      heights: [40, 200, 120, 120, 120],
      widths: ['*', '*', '*', '*']
    }
  };
};

const metaDataTable = (data: any) => {
  return {
    layout: 'noBorders',
    style: 'tableExample',
    table: {
      body: buildMetaDataBody(data),
      headerRows: 1,
      heights: [40, 200, 120, 120, 120],
      widths: ['*', '*', '*', '*']
    }
  };
};

const employmentsGeneralInfo = (data: any) => {
  return {
    layout: 'noBorders',
    style: 'tableData',
    table: {
      body: buildTableBody([data], true)
    }
  };
};

/**
 * Look for currency values in an object and format them.
 *
 * !! This method mutates the object recursively, so be careful when using it.
 */
function currencyFormatObject(obj: Record<any, any>, parentKey = '') {
  const keys = Object.keys(obj);

  keys.forEach(function (key) {
    const value = obj[key];

    if (
      typeof value === 'number' &&
      (MONEY_FIELDS.includes(key) || MONEY_FIELDS.includes(parentKey))
    ) {
      obj[key] = currencyFormat(value);
    } else if (Array.isArray(value)) {
      value.forEach((item) => {
        currencyFormatObject(item);
      });
    } else if (isPlainObject(value)) {
      currencyFormatObject(obj[key], key);
    }
  });
}

export const currencyFormat = (value: unknown, currency = 'GBP') => {
  if (typeof value !== 'number') return value;

  return `${new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).format(value)}`;
};

const buildTableBody = (data: any, isEmployment?: boolean) => {
  const body: any[] = [];

  data.forEach((row: any) => {
    Object.keys(row)
      .filter((row) => row !== 'tax_year')
      .forEach((key) => {
        const value = row[key];

        if (
          !(row[key] instanceof Array) ||
          (row[key] instanceof Array &&
            row[key].length > 0 &&
            typeof row[key][0] === 'string')
        ) {
          body.push([
            {
              text: `${camelCaseToFirstLetterUpperCase(key)}:`,
              bold: true,
              fontSize: 36,
              margin: isEmployment ? [0, 18, 0, 18] : [0, 0, 0, 0]
            },
            isEmployment
              ? {
                  text: value,
                  alignment: 'right',
                  margin: [0, 18, 0, 18],
                  fontSize: 36,
                  color: '#333'
                }
              : { text: value, fontSize: 36 }
          ]);
        }
      });
  });

  return body;
};

const buildPersonalDataBody = (data: any) => {
  const body: any[] = [];

  data.forEach((row: any) => {
    Object.keys(row)
      .filter((row) => row !== 'tax_year')
      .forEach((key, index) => {
        const value = row[key];
        if (
          !(row[key] instanceof Array) ||
          (row[key] instanceof Array &&
            row[key].length > 0 &&
            typeof row[key][0] === 'string')
        ) {
          if (key !== 'nino') {
            body.push([
              {
                text: `${camelCaseToFirstLetterUpperCase(key)}:`,
                bold: true,
                fontSize: 36,
                margin: index === 1 ? [0, 80, 0, 0] : [0, 0, 0, 0]
              },
              {
                text: value instanceof Array ? value.join(', ') : value,
                fontSize: 36,
                margin: index === 1 ? [0, 80, 0, 0] : [0, 0, 0, 0],
                colSpan: index === 1 ? 1 : 3
              },
              index !== 1
                ? ''
                : {
                    text: 'Nino:',
                    bold: true,
                    fontSize: 36,
                    margin: [0, 80, 0, 0]
                  },
              index !== 1
                ? ''
                : {
                    text: row['nino'],
                    fontSize: 36,
                    margin: [0, 80, 0, 0]
                  }
            ]);
          }
        }
      });
  });

  return body;
};

const buildMetaDataBody = (data: any) => {
  const body: any[] = [];

  data.forEach((row: any) => {
    Object.keys(row)
      .filter((row) => row !== 'tax_year')
      .forEach((key, index) => {
        const value = row[key];
        if (
          !(row[key] instanceof Array) ||
          (row[key] instanceof Array &&
            row[key].length > 0 &&
            typeof row[key][0] === 'string')
        ) {
          body.push([
            {
              text: `${camelCaseToFirstLetterUpperCase(key)}:`,
              bold: true,
              fontSize: 36,
              margin: index === 1 ? [0, 80, 0, 0] : [0, 0, 0, 0]
            },
            {
              text: value,
              fontSize: 36,
              margin: index === 1 ? [0, 80, 0, 0] : [0, 0, 0, 0]
            },
            {},
            {}
          ]);
        }
      });
  });

  return body;
};

const buildPaymentTableBody = (data: any) => {
  const body: any[] = [];

  const header = [
    { text: 'Date', bold: true, fontSize: 26, style: 'tableCell' },
    {
      text: 'Income Tax Payed',
      bold: true,
      style: ['alignRight', 'tableCell']
    },
    {
      text: 'NIC Payed',
      bold: true,
      style: ['alignRight', 'tableCell']
    },
    {
      text: 'Taxable Income',
      bold: true,
      style: ['alignRight', 'tableCell']
    }
  ];
  body.push(header);

  data.forEach((item: any) => {
    const row = [
      { text: item.date, color: '#333', style: 'tableCell' },
      {
        text: item.incomeTaxPayed,
        color: '#333',
        style: ['alignRight', 'tableCell']
      },
      { text: item.NIpayed, color: '#333', style: ['alignRight', 'tableCell'] },
      {
        text: item.taxableIncome,
        color: '#333',
        style: ['alignRight', 'tableCell']
      }
    ];
    body.push(row);
  });

  return body;
};

const employmentSection = (employments: any[]) => {
  const data: any[] = [];
  employments.map((employment) => {
    data.push(
      { text: `General Info\n\n`, style: 'subheader1' },
      employmentsGeneralInfo(employment),
      { text: '', margin: [0, 0, 0, 120] },
      { text: `Payments breakdown\n\n\n`, style: 'subheader1' },
      generatePaymentTables(employment.payments),
      {
        text: `\n\n`,
        style: 'subheader1'
      }
    );
  });

  return data;
};

function getIncomeSourcesAmountsPerType(
  incomeBreakdownReport: IncomeBreakdownReport
): IncomeSourcesBreakdown {
  const incomeSources: Source[] = incomeBreakdownReport.income.breakdown;
  const amountIncomeTypePairs: IncomeSourcesBreakdown = {
    selfEmployed: '£0.00',
    employment: '£0.00',
    otherIncome: '£0.00',
    totalIncome: `${incomeBreakdownReport.income.total}`
  };

  if (incomeSources && incomeSources.length > 0) {
    for (const incomeSource of incomeSources) {
      switch (incomeSource.type) {
        case IncomeSourceType.SELF_EMPLOYMENT:
          amountIncomeTypePairs.selfEmployed = `${incomeSource.amount}`;
          break;
        case IncomeSourceType.EMPLOYMENT:
          amountIncomeTypePairs.employment = `${incomeSource.amount}`;
          break;
        case IncomeSourceType.OTHER_INCOME:
          amountIncomeTypePairs.otherIncome = `${incomeSource.amount}`;
          break;
      }
    }
  }

  return amountIncomeTypePairs;
}

const generateIncomeBreakdownReportBody = (
  incomeBreakdownReport: IncomeBreakdownReport
) => {
  const incomeSourcesAmountsPerType = getIncomeSourcesAmountsPerType(
    incomeBreakdownReport
  );
  const data: any[] = [
    { text: `Income Sources\n\n`, style: 'subheader1' },
    {
      style: 'tableData',
      layout: {
        hLineWidth: function () {
          return 3;
        },
        vLineWidth: function () {
          return 3;
        }
      },
      table: {
        body: [
          [
            {
              text: `Self-Employed:`,
              border: [false, false, false, false],
              bold: true,
              fontSize: 36,
              margin: [0, 18, 0, 18]
            },
            {
              text: incomeSourcesAmountsPerType.selfEmployed,
              border: [false, false, false, false],
              alignment: 'right',
              margin: [0, 18, 0, 18],
              fontSize: 36,
              color: '#333'
            }
          ],
          [
            {
              text: `Employment:`,
              border: [false, false, false, false],
              bold: true,
              fontSize: 36,
              margin: [0, 18, 0, 18]
            },
            {
              text: incomeSourcesAmountsPerType.employment,
              border: [false, false, false, false],
              alignment: 'right',
              margin: [0, 18, 0, 18],
              fontSize: 36,
              color: '#333'
            }
          ],
          [
            {
              text: `Other Income:`,
              border: [false, false, false, false],
              bold: true,
              fontSize: 36,
              margin: [0, 18, 0, 18]
            },
            {
              text: incomeSourcesAmountsPerType.otherIncome,
              border: [false, false, false, false],
              alignment: 'right',
              margin: [0, 18, 0, 18],
              fontSize: 36,
              color: '#333'
            }
          ],
          [
            {
              text: `Total Income:`,
              border: [false, true, false, false],
              bold: true,
              fontSize: 36,
              margin: [0, 18, 300, 18]
            },
            {
              text: incomeSourcesAmountsPerType.totalIncome,
              border: [false, true, false, false],
              alignment: 'right',
              margin: [0, 18, 0, 18],
              fontSize: 36,
              color: '#333'
            }
          ]
        ]
      }
    },
    { text: '', margin: [0, 0, 0, 120] }
  ];

  return data;
};
