import React, { useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  SortEnum,
  SortMenu,
  SelectMenu,
  SelectMenuItem
} from '@/components';
import { ReactComponent as SearchIcon } from '@/assets/image/icons/search.svg';
import { ReactComponent as CaretDownIcon } from '@/assets/image/icons/caret-down.svg';
import { EntrypointTableRow } from './EntryPointTableRow';
import styles from './style.module.css';
import { IModal, Modal } from '@/components/Modal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { sortString } from '@/utils/string';

export type EntrypointContent = {
  title: string;
  url: string;
  id: string;
  remainingUses: number;
  expiresAt?: Date;
};

type EntrypointTableProps = {
  tableContent: EntrypointContent[];
  onDeleteEntrypoint: (entrypointId: string) => Promise<void>;
  onCreateEntrypoint: () => void;
  onCopyEntrypoint: (url: string) => void;
};

export const EntrypointTable: React.FC<EntrypointTableProps> = ({
  tableContent,
  onDeleteEntrypoint,
  onCreateEntrypoint,
  onCopyEntrypoint
}) => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [sortType, setSortType] = useState<SortEnum>(SortEnum.NO);

  const [deleteModal, setDeleteModal] = React.useState<IModal>({
    showModal: false,
    title: 'Delete Entrypoint?',
    description: '',
    action: async () => {}
  });

  const handleChangeSelection = (id: string) => {
    const filtered = selected.filter((item) => item !== id);
    if (filtered.length === selected.length) {
      setSelected([...selected, id]);
    } else {
      setSelected(filtered);
    }
  };

  const handleBulkSelection = () => {
    if (selected.length !== tableContent.length) {
      setSelected(tableContent.map((item) => item.id));
    } else {
      setSelected([]);
    }
  };

  const handleDelete = (content: EntrypointContent) => {
    setDeleteModal({
      ...deleteModal,
      showModal: true,
      description: `Are you sure that you want to delete entrypoint ${content.title}?`,
      action: async () => {
        try {
          await onDeleteEntrypoint(content.id);
          toast.success('Deleted!');
        } catch (e: any) {
          toast.error(e?.message || 'There was an error deleting entrypoint');
        } finally {
          setDeleteModal({
            ...deleteModal,
            showModal: false
          });
        }
      }
    });
  };

  const handleCopy = (content: EntrypointContent) => {
    if (content.url) {
      onCopyEntrypoint(content.url);
    } else {
      toast.warning('Entrypoint missing URL!');
    }
  };

  const goToSessionPage = (content: EntrypointContent) => {
    navigate({
      pathname: `/entrypoints/${content.id}/sessions`
    });
  };

  const filteredTableContent = useMemo(() => {
    const items = tableContent.filter(
      (item) => item.title.toLowerCase().indexOf(search.toLowerCase()) > -1
    );

    if (sortType !== SortEnum.NO) {
      return items.sort((a, b) =>
        sortString(a.title, b.title, sortType === SortEnum.ASC)
      );
    }

    return items;
  }, [tableContent, search, sortType]);

  const entrypointActions: SelectMenuItem[] = [
    {
      label: 'Remind',
      action: () => {}
    }
  ];

  return (
    <div>
      <Modal modal={deleteModal} setModal={setDeleteModal} />

      <div className="flex mb-2.5">
        <div className="flex flex-1 justify-center">
          <div className="flex items-center border-b border-b-gray-300 max-w-[255px] h-[28px] w-full">
            <SearchIcon className="mr-1.5" />
            <input
              className="border-0 focus:border-0 focus:outline-0"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <Button
          className="w-20 h-6 rounded text-white text-sm bg-apari-purple p-0 hover:bg-blue-800"
          onClick={onCreateEntrypoint}
        >
          Create
        </Button>
      </div>

      <div className={styles.table}>
        <div className="flex px-4 border-b border-b-gray-200 h-11">
          <div className="w-2/5 flex items-center">
            <Checkbox
              onChange={handleBulkSelection}
              checked={!!selected.length}
              status={
                selected.length === tableContent.length ? 'check' : 'minus'
              }
            />
            <div className="flex items-center cursor-pointer">
              <span className="text-base font-bold ml-3.5 mr-2">Name</span>
              <SortMenu onChangeSort={setSortType} />
            </div>
          </div>
          <div className="w-1/5 flex items-center">
            <p className="text-base font-bold flex-1">Expires On</p>
          </div>
          <div className="w-1/5 flex items-center">
            <p className="text-base font-bold flex-1">Remaining Uses</p>
          </div>
          <div className="w-1/5 flex items-center">
            {!!selected.length && (
              <SelectMenu
                classNameMenuBtn={styles.actionButton}
                items={entrypointActions}
                label={`Actions`}
                selectDropdownIcon={
                  <CaretDownIcon className="text-apari-purple transform rotate-180" />
                }
              />
            )}
          </div>
        </div>

        {filteredTableContent.map((item, index) => (
          <EntrypointTableRow
            key={index}
            data={item}
            onClick={goToSessionPage}
            onSelect={handleChangeSelection}
            onDelete={handleDelete}
            onCopy={handleCopy}
            selected={selected.includes(item.id)}
          />
        ))}
      </div>
    </div>
  );
};
