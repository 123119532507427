import { combineClassNames } from '@/utils/string';
import { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.css';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'primary' | 'default';
}
export const Button = (_props: ButtonProps) => {
  const { theme = 'default', className, ...props } = _props;

  return (
    <button
      {...props}
      className={combineClassNames(
        styles.button,
        styles[`button-${theme}`],
        className
      )}
    />
  );
};
