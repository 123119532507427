import React, { useState } from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as CaretDownIcon } from '@/assets/image/icons/caret-down.svg';
import { ReactComponent as CheckIcon } from '@/assets/image/icons/check-solid.svg';
import clsx from 'clsx';

export enum SortEnum {
  ASC = 'asc',
  DESC = 'desc',
  NO = 'no'
}

export type SortOption = {
  key: SortEnum;
  label: string;
};

const sortOptions: SortOption[] = [
  { key: SortEnum.ASC, label: 'Sort ascending' },
  { key: SortEnum.DESC, label: 'Sort descending' },
  { key: SortEnum.NO, label: 'Clear sorting' }
];

type Props = {
  onChangeSort?: (type: SortEnum) => void;
};

export const SortMenu: React.FC<Props> = ({ onChangeSort }) => {
  const [value, setValue] = useState<SortEnum>(SortEnum.NO);

  const handleChangeOption = (key: SortEnum) => {
    setValue(key);
    onChangeSort?.(key);
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        className={clsx(
          'mt-1 w-3.5 h-3.5 border border-gray-300 flex items-center justify-center cursor-pointer rounded-sm',
          value === SortEnum.NO && 'border-gray-200'
        )}
      >
        <CaretDownIcon
          className={clsx(
            value === SortEnum.NO ? 'text-gray-300' : 'text-gray-600',
            {
              'transform rotate-180': value === SortEnum.ASC
            }
          )}
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-5 top-0 w-[160px] bg-white shadow-md">
          <div>
            {sortOptions.map((item, index) => (
              <Menu.Item key={index}>
                <div
                  className="flex items-center text-sm py-1 px-2 hover:bg-gray-200"
                  onClick={() => handleChangeOption(item.key)}
                >
                  <CheckIcon
                    className={clsx(
                      'fill-green-600 w-3 mr-2',
                      value !== item.key && 'opacity-0'
                    )}
                  />
                  {item.label}
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
