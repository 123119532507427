import { Navigate } from 'react-router-dom';
import { Entrypoints } from '~/Entrypoints';
import { Sessions } from '~/Sessions';
import { SignIn } from '~/SignIn';
import AuthLayout from '@/layout/Auth';
import DefaultLayout from '../layout/Default';
import type { AppRoute } from '@/types';
import { HomeIcon } from '@heroicons/react/20/solid';

export const routes: AppRoute[] = [
  {
    path: '/',
    element: <DefaultLayout />,
    key: 'AppLayout',
    breadcrumb: () => (
      <>
        <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
        <span className="sr-only">Home</span>
      </>
    ),
    routes: [
      {
        index: true,
        key: 'Index',
        element: <Entrypoints />
      },
      {
        path: '/entrypoints/:entrypointId/sessions',
        key: 'Sessions',
        element: <Sessions />
      },
      {
        path: '/entrypoints',
        key: 'Entrypoints',
        element: <Entrypoints />
      }
    ]
  },
  {
    key: 'AuthLayout',
    path: '/',
    element: <AuthLayout />,
    routes: [
      {
        path: '/sign-in',
        key: 'SignIn',
        element: <SignIn />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" />,
    key: 'Not found'
  }
];
