export function mergeStrings(...strings: (string | undefined)[]) {
  return strings.join(' ').trim();
}

/**
 * Alias name of mergeStrings, use to merge classNames.
 */
export function combineClassNames(...args: Parameters<typeof mergeStrings>) {
  return mergeStrings(...args);
}

export function sortString(a: string, b: string, asc: boolean): number {
  if (a.toLowerCase() < b.toLowerCase()) return asc ? -1 : 1;
  if (a.toLowerCase() > b.toLowerCase()) return asc ? 1 : -1;
  return 0;
}
