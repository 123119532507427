import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Challenge } from './containers/Challenge';
import { SignInForm } from './SignInForm';
import styles from './style.module.css';
import { ISignInLocation } from '~/SignIn/signin-types';
import {
  matomoSignInBtnClick,
  matomoSignInSuccess,
  matomoSignInError
} from '~/SignIn/sign-in-helpers';

export const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation() as ISignInLocation;

  const [challengeUser, setChallengeUser] = useState<CognitoUser>();

  const signIn: React.ComponentProps<typeof SignInForm>['onSubmit'] = async ({
    email,
    password
  }) => {
    matomoSignInBtnClick();

    try {
      const user: CognitoUser = await Auth.signIn({
        username: email,
        password
      });

      // If there is a challenge, users have to complete it before logging in.
      if (user.challengeName) {
        setChallengeUser(user);
        return;
      }

      toast.success('Success');

      matomoSignInSuccess();

      navigate(state?.destination ?? '/');
    } catch (e: any) {
      console.error(e);
      matomoSignInError();

      if (e?.code === 'NotAuthorizedException') {
        toast(e.message);
        return;
      }

      toast(e.DEFAULT_ERROR_MESSAGE);
    }
  };

  const onChallengeComplete = () => {
    navigateUserToHomePage();
    toast.success('Success');
  };

  const onChallengeFail = (e: any) => {
    toast(e?.message || 'Failed to complete the challenge');
  };

  const navigateUserToHomePage = () => {
    navigate('/', { replace: true });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.signIn}>
        <div className="w-full max-w-[392px] lg:max-w-[319px]">
          <div className="flex items-center mb-3 lg:mb-8">
            <p className="font-bold font-robot text-gray-700">
              Income Verification
            </p>
          </div>
          <h1 className={styles.title}>WELCOME TO APARI!</h1>
          <p className="mb-6 lg:mb-8 mt-1 font-robot">
            Sign in to income verification dashboard
          </p>
        </div>

        {!challengeUser ? (
          <SignInForm onSubmit={signIn} />
        ) : (
          <Challenge
            user={challengeUser}
            onChallengeComplete={onChallengeComplete}
            onChallengeFail={onChallengeFail}
          />
        )}
      </div>
    </div>
  );
};
