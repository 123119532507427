export const MONEY_FIELDS = [
  'amount',
  'tax',
  'sumToPay',
  'total',
  'penalties',
  'adjustments',
  'interest',
  'surcharges',
  'payments',
  'profits',
  'NIpayed',
  'totalNIpayed',
  'totalTaxableIncome',
  'totalIncomeTaxPayed',
  'taxableIncome',
  'incomeTaxPayed'
];

export enum ReportType {
  PERSONAL,
  INCOME_BREAKDOWN,
  EMPLOYMENT,
  UNKNOWN
}

export enum EmploymentReportType {
  EMPTY_EMPLOYMENT,
  NON_EMPTY_EMPLOYMENT
}

export interface IncomeSourcesBreakdown {
  selfEmployed: string;
  employment: string;
  otherIncome: string;
  totalIncome: string;
}

export interface IncomeBreakdownReport {
  income: {
    breakdown: Source[];
    total: string;
  };
}

export interface Source {
  type: IncomeSourceType;
  amount: string;
}

export enum IncomeSourceType {
  SELF_EMPLOYMENT = 'Self-employment',
  OTHER_INCOME = 'Other income',
  EMPLOYMENT = 'Employment'
}
