import backend from '../../common/api/backend';
import { reactAppConfig } from '@/config/react-app-config';
import {
  FetchReportResponse,
  FetchSessionsResponse
} from '~/Sessions/sessions-types';

export const getSessions = (
  entrypointId: string
): Promise<FetchSessionsResponse> => {
  return backend
    .get(`${reactAppConfig.backendEnv}/entrypoints/${entrypointId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Failed to fetch the sessions:', JSON.stringify(error));
      return error.status;
    });
};

export const getReport = (
  entrypointId: string,
  sessionId: string
): Promise<FetchReportResponse> => {
  return backend
    .get(
      `${reactAppConfig.backendEnv}/entrypoints/${entrypointId}/${sessionId}`
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error('Failed to fetch the report:', JSON.stringify(error));
      return error.status;
    });
};

export const deleteSession = (
  entrypointId: string,
  id: string
): Promise<FetchSessionsResponse> => {
  return backend
    .delete(`${reactAppConfig.backendEnv}/entrypoints/${entrypointId}/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Failed to delete the session:', JSON.stringify(error));
      throw error;
    });
};
