import { combineClassNames } from '@/utils/string';
import { InputHTMLAttributes } from 'react';
import styles from './Input.module.css';
import { InputPassword } from './InputPassword';

const InputDefault = (props: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <input
      {...props}
      className={combineClassNames(styles.input, props.className)}
    />
  );
};

export const Input = (props: InputHTMLAttributes<HTMLInputElement>) => {
  switch (props.type) {
    case 'password':
      return <InputPassword {...props} />;
    default:
      return <InputDefault {...props} />;
  }
};
