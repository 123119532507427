import { Input, Button, Loading } from '@/components';
import { Formik, FormikConfig } from 'formik';
import { useState } from 'react';
import styles from './style.module.css';
import { ReactComponent as EyeOpenIcon } from '@/assets/image/icons/eye-open.svg';
import { ReactComponent as EyeCrossedIcon } from '@/assets/image/icons/eye-crossed.svg';
import { matomoResetPasswordBtnClick } from '~/SignIn/sign-in-helpers';

interface SignInFormValues {
  email: string;
  password: string;
}

type SignInFormProps = Pick<
  FormikConfig<SignInFormValues>,
  'onSubmit' | 'onReset'
>;

export const SignInForm = ({ onSubmit, ...props }: SignInFormProps) => {
  const initialValues: SignInFormValues = {
    email: '',
    password: ''
  };

  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);

  const customOnSubmit: SignInFormProps['onSubmit'] = async (
    values,
    helpers
  ) => {
    setLoading(true);
    await onSubmit(values, helpers);
    setLoading(false);
  };

  const onResetPassword = (): void => {
    matomoResetPasswordBtnClick();
  };

  return (
    <Formik
      {...props}
      onSubmit={customOnSubmit}
      initialValues={initialValues}
      className="pr-0 lg:pr-8"
    >
      {({ values, handleChange, submitForm }) => (
        <>
          <Input
            value={values.email}
            onChange={handleChange}
            placeholder="Email"
            name="email"
            className={styles.input}
          />

          <div className="relative max-w-[288px]">
            <Input
              name="password"
              value={values.password}
              onChange={handleChange}
              placeholder="Password"
              type={visiblePassword ? 'text' : 'password'}
              className={styles.input}
            />

            <div
              className="lg:mt-0 absolute top-2 right-4 flex justify-center items-center"
              onClick={() => setVisiblePassword(!visiblePassword)}
            >
              {!visiblePassword ? (
                <EyeCrossedIcon className="w-5 h-5 fill-gray-300" />
              ) : (
                <EyeOpenIcon className="w-5 h-5 fill-gray-300" />
              )}
            </div>
          </div>
          <div className="mt-[-14px] lg:mt-0 mb-10">
            <a
              href="#"
              onClick={onResetPassword}
              className="text-xs font-medium underline text-gray-700"
            >
              Reset password?
            </a>
          </div>
          <Button
            onClick={submitForm}
            className={styles.button}
            disabled={isLoading}
          >
            {isLoading && <Loading />}
            Sign in
          </Button>
        </>
      )}
    </Formik>
  );
};
