import { Link, Outlet } from 'react-router-dom';
import styles from './style.module.css';

const AuthLayout = () => {
  return (
    <div className={styles.auth}>
      <div className="py-2 px-3 h-[72px]">
        <div className="h-full flex justify-start items-center">
          <Link to="/">
            <img width={76} height="auto" src="/logo.png" alt="logo" />
          </Link>
        </div>
      </div>
      <div className={styles.authContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
