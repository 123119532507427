import * as MATOMO from '@/constants/matomo';

export const matomoSignInBtnClick = (): void =>
  window._paq.push([
    MATOMO.EVENT_TYPE.TRACK_EVENT,
    MATOMO.SIGN_IN_EVENT.SIGN_IN,
    MATOMO.EVENT_TYPE.CLICK
  ]);

export const matomoSignInSuccess = (): void =>
  window._paq.push([
    MATOMO.EVENT_TYPE.TRACK_EVENT,
    MATOMO.SIGN_IN_EVENT.SIGN_IN,
    MATOMO.EVENT_TYPE.SUBMISSION,
    MATOMO.SIGN_IN_EVENT.SIGN_IN_SUCCESS
  ]);

export const matomoSignInError = (): void =>
  window._paq.push([
    MATOMO.EVENT_TYPE.TRACK_EVENT,
    MATOMO.SIGN_IN_EVENT.SIGN_IN,
    MATOMO.EVENT_TYPE.SUBMISSION,
    MATOMO.SIGN_IN_EVENT.SIGN_IN_ERROR
  ]);

export const matomoResetPasswordBtnClick = (): void =>
  window._paq.push([
    MATOMO.EVENT_TYPE.TRACK_EVENT,
    MATOMO.RESET_PASSWORD_EVENT.RESET_PASSWORD,
    MATOMO.EVENT_TYPE.CLICK
  ]);
