import React from 'react';
import { Checkbox } from '@/components';
import { Tooltip } from '@/components/Tooltip';
import { ReactComponent as CopyIcon } from '@/assets/image/icons/copy.svg';
import { ReactComponent as SendIcon } from '@/assets/image/icons/send.svg';
import { ReactComponent as DeleteIcon } from '@/assets/image/icons/delete.svg';
import { EntrypointContent } from '@/components/Table/EntrypointTable/index';
import styles from './style.module.css';
import { formatDateUK } from '@/utils/date';
import * as MATOMO from '@/constants/matomo';

type EntrypointTableRowProps = {
  data: EntrypointContent;
  onSelect: (id: string) => void;
  onDelete: (data: EntrypointContent) => void;
  onCopy: (data: EntrypointContent) => void;
  onClick: (data: EntrypointContent) => void;
  selected: boolean;
};

export const EntrypointTableRow: React.FC<EntrypointTableRowProps> = ({
  data,
  onSelect,
  selected,
  onDelete,
  onClick,
  onCopy
}) => {
  const handleSelect = () => {
    onSelect(data.id);
  };

  const handleClickRow = () => {
    window._paq.push([
      MATOMO.EVENT_TYPE.TRACK_EVENT,
      MATOMO.ENTRYPOINT_LIST_EVENT.ENTRYPOINT_LIST,
      MATOMO.EVENT_TYPE.CLICK,
      MATOMO.ENTRYPOINT_LIST_EVENT.GO_TO_SESSION_PAGE
    ]);
    onClick(data);
  };

  const preventPropagation = (event) => {
    event.stopPropagation();
  };

  const handleDelete = (event) => {
    window._paq.push([
      MATOMO.EVENT_TYPE.TRACK_EVENT,
      MATOMO.ENTRYPOINT_LIST_EVENT.ENTRYPOINT_LIST_ACTION,
      MATOMO.EVENT_TYPE.CLICK,
      MATOMO.ENTRYPOINT_LIST_EVENT.DELETE
    ]);
    preventPropagation(event);
    onDelete(data);
  };

  const handleCopy = (event) => {
    window._paq.push([
      MATOMO.EVENT_TYPE.TRACK_EVENT,
      MATOMO.ENTRYPOINT_LIST_EVENT.ENTRYPOINT_LIST_ACTION,
      MATOMO.EVENT_TYPE.CLICK,
      MATOMO.ENTRYPOINT_LIST_EVENT.COPY
    ]);
    preventPropagation(event);
    onCopy(data);
  };

  const handleSend = (event) => {
    window._paq.push([
      MATOMO.EVENT_TYPE.TRACK_EVENT,
      MATOMO.ENTRYPOINT_LIST_EVENT.ENTRYPOINT_LIST_ACTION,
      MATOMO.EVENT_TYPE.CLICK,
      MATOMO.ENTRYPOINT_LIST_EVENT.SEND_LINK_OVER_EMAIL
    ]);
    preventPropagation(event);
  };

  return (
    <div
      className="flex px-4 border-b border-b-gray-200 h-11 hover:bg-gray-200 cursor-pointer"
      onClick={handleClickRow}
    >
      <div className="w-2/5 flex items-center">
        <span onClick={preventPropagation}>
          <Checkbox onChange={handleSelect} checked={selected} />
        </span>
        <span className="ml-3.5 text-sm">{data.title}</span>
      </div>
      <div className="w-1/5 flex items-center">
        {data.expiresAt && (
          <span className="text-sm">{formatDateUK(data.expiresAt)}</span>
        )}
      </div>
      <div className="w-1/5 flex items-center">
        <span className="text-sm">{data.remainingUses}</span>
      </div>
      <div className="w-1/5 flex items-center justify-end">
        <div className="flex items-center">
          <div className="flex items-center">
            <Tooltip message="Copy">
              <span className={styles.iconButton} onClick={handleCopy}>
                <CopyIcon />
              </span>
            </Tooltip>
            <Tooltip message="Send link over email">
              <span className={styles.iconButton} onClick={handleSend}>
                <SendIcon />
              </span>
            </Tooltip>
            <Tooltip message="Delete">
              <span className={styles.iconButton} onClick={handleDelete}>
                <DeleteIcon />
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
