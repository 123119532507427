import React from 'react';
import { combineClassNames } from '@/utils/string';
import styles from './Checkbox.module.css';

type CheckboxProps = {
  status?: 'check' | 'minus';
  checked: boolean;
  onChange: (status: boolean) => void;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  status,
  checked,
  onChange
}) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <label className={styles.checkbox}>
      <input
        className={styles.checkboxInput}
        type="checkbox"
        onChange={handleChange}
        checked={checked}
      />
      <span
        className={combineClassNames(
          styles.mark,
          status === 'minus' ? styles.minusmark : styles.checkmark
        )}
      />
    </label>
  );
};
